<template>
    <div class="statePage overflow">
        <div class="stateList">
        <li v-for="(item,index) in useList" :key="index">
            <p>{{index+1}}.{{item.problem}}</p>
            <p class="baseMax">{{item.answer}}</p>
        </li>
   </div>
    <!-- <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="currentMethod" @prev-click="currentMethod" @next-click="currentMethod" >
    </el-pagination> -->
    <el-empty description="暂无使用手册~" v-if="useList.length==0"></el-empty>
    </div>
</template>
    <script>
import api from '../../http/api';

export default {
    data() {
        return {
            useList: [],
            // total: 0,//总页数
            // pageSize: 10,//分页
            // pageIndex: 1,//当前页码
        }
    },
    created() {
    //this.getList()
    },
    activated(){
    this.getList();
},
    methods: {
        // 当前页
        currentMethod(index){
          this.getList(index)
         },
        
     async getList(data){
       let that = this;
        that.pageIndex=data?data:that.pageIndex
        let result = await api.getUseBook({
            page_size:that.pageSize,
            page_index: that.pageIndex,//当前页码
       })
       if(result){
       // that.total=result.total;
        //console.log('total',that.total)
        that.useList=result
       }
        //console.log('result',result)
      }
    }
}
</script>
    <style lang="less" scoped>
    .statePage {
        border: 1px solid #DCDCDC;
        height: 100%;
        max-height: 100%;
      
        padding: 18px 24px;
        overflow-x: hidden;
       .stateList{
        height:94%;
        overflow-y: auto;
       }
        li {
            padding-bottom: 20px;
    
            p {
                color: #9e9e9e
            }
    
            p:first-child {
                color: #000000;
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 8px;
            }
        }
        /deep/ .el-pagination{
            left:50%;
            transform: translateX(-50%);
        }
    }
    </style>
